<template>
	<div class="p-grid">

        <div class="p-col-12 p-md-4">
            <div class="card p-fluid">
                <h5>Product Feature Request</h5>
                <div class="para">                    
                    Please use this form to request new features or functionality, or changes to existing features or functionality for the ArenaCX product.
                </div>

                <div class="para">                    
                    Note: To report issues with current features that are not working as designed, please go here.
                </div>
            </div>
        </div>
    
        <div class="p-col-12 p-md-8">

			<div class="card p-fluid">
                <div class="p-formgrid p-grid">
					<div class="p-field p-col">
                            <label for="name">Name</label>
                            <InputText id="name" type="text" />
					</div>
					<div class="p-field p-col">
                            <label for="email">Email</label>
                           <InputText id="email" type="email" />
					</div>
					<div class="p-field p-col">
                            <label for="company">Company</label>
                            <InputText id="company" type="text" />
					</div>

				</div>

                <div class="p-formgrid p-grid">					
 
					<div class="p-field p-col">
                            <label for="urgency">Urgency</label>
                            <Dropdown id="urgency" v-model="urgencySelected" :options="urgencyOptions" optionLabel="name"></Dropdown>
					</div>
					<div class="p-field p-col">
                            <label for="company">Is this a new feature or change?</label>
                            <Dropdown id="urgency" v-model="requestForType" :options="requestForTypes" optionLabel="name"></Dropdown>
					</div>
				</div>
 
                <div class="p-formgrid p-grid">					
					<div class="p-field p-col">
                        <label for="requestImpacts">Please select the category of metrics this request will most impact</label>
                        <MultiSelect id="requestImpacts" v-model="requestImpacts" :options="requestImpactOptions" optionLabel="name" :filter="true" class="multiselect-custom" >
                            <template #option="slotProps">
                                <div class="country-item">
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </MultiSelect>
                    </div>
					<div class="p-field p-col">
                            <label for="mostBenefit">Is this a new feature or change?</label>
                            <Dropdown id="mostBenefit" v-model="mostBenefit" :options="mostBenefitOptions" optionLabel="name"></Dropdown>
					</div>
				</div>
 

				<div class="p-field p-col">
					<label for="description" >Describe your request</label>
					<div  >
						<Textarea id="description" type="text" style="height:200px"/>
					</div>
				</div>				
                <div class="p-field p-grid">
					<div class="p-col-12 p-mb-10 p-md-10 p-mb-md-0"></div>
					<div class="p-col-12 p-md-2" >
						<Button label="Submit"></Button>
					</div>
				</div>
				
			</div>

			

			
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				urgencyOptions: [
					{name: '1 - Most Urgent', code: '1'},
					{name: '2', code: '2'},
					{name: '3', code: '3'},
					{name: '4', code: '3'},
					{name: '5 - Least Urgent', code: '3'}
				],
				urgencySelected: null,
                
                requestForTypes: [
					{name: 'Brand new feature/functionality', code: '1'},
					{name: 'Change to existing feature/functionality', code: '2'}
				],
				requestForType: null,

				requestImpacts: null,
				requestImpactOptions: [
					{name: 'Cost to Serve', code: 'Cost to Serve'},
					{name: 'Productivity', code: 'Productivity'},
					{name: 'Performance', code: 'Performance'},
					{name: 'Quality', code: 'Quality'},
					{name: 'Not applicable', code: 'NA'} 
				],

				mostBenefit: null,
				mostBenefitOptions: [
					{name: 'Customer Support', code: 'Customer Support'},
					{name: 'Operations', code: 'Operations'},
					{name: 'Billing', code: 'Billing'},
					{name: 'Partner Support', code: 'Partner Support'},
					{name: 'Other', code: 'Other'} 
				]

			}
		}
	}
</script>

<style scoped>
    .para {
        margin: 18px;
    }
</style>